export class Voiture{
idVoiture='';
nom='Clio 4';
prix=3000;
date_d_ajout='29/06/2020';
marque='Renault';
modele='Clio 4';
annee_modele=2000;
 mise_en_circulation=2000;
 kilometrage=124000;
 carburant='Gazoil';
 boite_de_vitesse='Manuelle';
 type_de_vehicule='Citadine';
 couleur='Rouge';
 nombre_de_places=5;
 puissance_fiscale='5 Cv';
 puissance_DIN='vide';
 permis='Avec Permis';
 soumis_LOA_LLD='vide';
 description='Clio 4 tres bon etat, rien a prévoir CT ok vidage OK ...';
 adresse='Bezons';
 statut='A vendre';
}