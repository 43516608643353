

//export const SERVER_URL = "http://localhost:8080/";
//export const SERVER_URL = "https://chbautos.herokuapp.com/";
export const SERVER_URL = "https://chbautos.fr/"

export const login = "0621938146";
export const motdepasse = "chbauto2020";

export const horraire=[
    {heure:"08:00"},    {heure:"08:30"},
    {heure:"09:00"},    {heure:"09:30"},
    {heure:"10:00"},    {heure:"10:30"},
    {heure:"11:00"},    {heure:"11:30"},
    {heure:"12:00"},    {heure:"12:30"},
    {heure:"13:00"},    {heure:"13:30"},
    {heure:"14:00"},    {heure:"14:30"},
    {heure:"15:00"},    {heure:"15:30"},
    {heure:"16:00"},    {heure:"16:30"},
    {heure:"17:00"},    {heure:"17:30"},
    {heure:"18:00"},    {heure:"18:30"},
    {heure:"19:00"},    {heure:"19:30"},
];
