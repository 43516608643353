import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { Router } from '@angular/router';
import { Produit } from 'app/models/Produit';
import { Facture } from 'app/models/Facture';
import { login } from 'app/Utils/const';

@Component({
  selector: 'app-create-facture',
  templateUrl: './create-facture.component.html',
  styleUrls: ['./create-facture.component.css']
}) 
export class CreateFactureComponent implements OnInit {

  constructor(private  authService: AuthService, private router: Router) { }

  facture: any;
  produit: Produit;
  total;
  totalsansTVA;
  factureTVA;
  listProduitQuantite;
  prixQuant=0;
  //tva = 20/100;
  public loading = false;
  messageErreur;
  paiement;


  ngOnInit(): void {
    this.authService.espaceAdmin();
    this.produit = new Produit;
    this.facture = new Facture;
    this.facture.idFacture=null;
    this.loadCart();
    this.removeByKey();
  }  

 
 
  ajouterProduit(){    
    this.prixQuant = this.produit.quantite * (this.produit.prixUnite + (this.produit.prixUnite * (this.produit.tva/100)));
     this.prixQuant = Math.round(this.prixQuant * 100) / 100; //2 chiffres apres la virgules
    this.produit.prixQuant = this.prixQuant;
    if (sessionStorage.getItem('cart') == null) {
      let cart: any = [];
      cart.push(JSON.stringify(this.produit));
      sessionStorage.setItem('cart', JSON.stringify(cart));
    }
    else {
      let cart: any = JSON.parse(sessionStorage.getItem('cart'));
      cart.push(JSON.stringify(this.produit));
      sessionStorage.setItem('cart', JSON.stringify(cart));
    }
    let cart = JSON.parse(sessionStorage.getItem('cart'));
    this.loadCart();
    this.produit = new Produit;
  }

 
  loadCart(): void {
    this.total = 0;
    this.totalsansTVA = 0;
    this.factureTVA = 0;
		this.listProduitQuantite = [];
    let cart = JSON.parse(sessionStorage.getItem('cart'));

    if(cart != null){
		for (var i = 0; i < cart.length; i++) {
      let produitQuantite = JSON.parse(cart[i]);
      this.total += produitQuantite.prixQuant;
      this.total = Math.round(this.total * 100) / 100; //2 chiffres apres la virgules
      this.totalsansTVA += produitQuantite.prixUnite * produitQuantite.quantite;
      this.totalsansTVA = Math.round(this.totalsansTVA * 100) / 100; //2 chiffres apres la virgules
      this.factureTVA += produitQuantite.tva / cart.length; 
      this.listProduitQuantite.push({
				reference: produitQuantite.reference,
        designation: produitQuantite.designation,
        prixUnite: produitQuantite.prixUnite,
        tva: produitQuantite.tva,
        quantite: produitQuantite.quantite,
        prixQuant: produitQuantite.prixQuant
      });
    }  
  }
}

saveProduitFacture(idFacture){ 
  this.listProduitQuantite = [];
  let cart = JSON.parse(sessionStorage.getItem('cart'));
  if(cart != null){
  for (var i = 0; i < cart.length; i++) {
    let produitQuantite = JSON.parse(cart[i]);
    this.total += produitQuantite.prixQuant;
    this.total = Math.round(this.total * 100) / 100;
    this.totalsansTVA += produitQuantite.prixUnite * produitQuantite.quantite;
    this.totalsansTVA = Math.round(this.totalsansTVA * 100) / 100; //2 chiffres apres la virgules
    this.factureTVA += produitQuantite.tva / cart.length;
    this.listProduitQuantite.push({ 
      idFacture: idFacture,
      reference: produitQuantite.reference,
      designation: produitQuantite.designation,
      prixUnite: produitQuantite.prixUnite,
      tva: produitQuantite.tva,
      quantite: produitQuantite.quantite,
      prixQuant: produitQuantite.prixQuant
    });
  }  
}
//console.log(this.listProduitQuantite);
this.authService.saveProduitFacture(this.listProduitQuantite)
.subscribe(data=>{
  //console.log(data);
},
error => {
  console.log(error);
}
);
} 

removeByKey(){
    sessionStorage.removeItem('cart');
    this.loadCart();
}

createFactureFct(facture){
//console.log("this.facture.idFacture");
//console.log(this.facture.idFacture);
if(this.facture.idFacture===null) {
  alert('Veuillez saisir le numero de facture svp'); 
}else{
  this.createFacture(facture)
}
}
 
createFacture(facture){
  this.loading = true;
//facture.total = this.total;
facture.total = Math.round(this.total * 100) / 100;
//facture.totalsansTVA = this.totalsansTVA;
facture.totalsansTVA = Math.round(this.totalsansTVA * 100) / 100; //2 chiffres apres la virgules
facture.factureTVA = this.factureTVA;
facture.paiement = this.paiement;
  this.authService.saveFacture(facture) 
  .subscribe(data=>{
    let idFacture = data;
    this.saveProduitFacture(idFacture);
    this.loading = false;
    alert('facture enregistrée'); 
    this.router.navigate(['/detailFacture/'+idFacture]);
 //   this.detailFacture(idFacture);
    this.removeByKey();
  },
  error => {
    console.log(error);
    console.log(error.error.message);
    if(error.error.message==='idFacture_existe'){
      this.messageErreur='Une facture avec le numero saisie Existe déja, veuillez le changer SVP';
    }else{
      this.messageErreur='facture n est enregistrée ERREUR coté serveur !!!';
    }
    this.loading = false;
    alert(this.messageErreur);
  }
);
}




removeProduit(reference: string): void {
  let confirm = window.confirm('êtes-vous sûr de vouloir supprimer le produit?');
  if(confirm==true){ 
    let cart: any = JSON.parse(sessionStorage.getItem('cart'));
    let index: number = -1;
    for (var i = 0; i < cart.length; i++) {
      let produitQuantite: Produit = JSON.parse(cart[i]);
      if (produitQuantite.reference == reference) {
        cart.splice(i, 1);
        break;
      }
    }
    sessionStorage.setItem("cart", JSON.stringify(cart));
    this.loadCart();
 }
}



}
