import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/confSecurity/auth.service';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

  lat: number = 36.7023037;
  long: number =  4.05289700000003;
  zoom: number = 17;

  constructor(private  authService: AuthService) { }
  listVoitures ;
  selectedFile: File = null;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;

  retrievedImage2: any;
  base64Data2: any;
  retrieveResonse2: any;
  public loading = false;

  ngOnInit(): void {
    //recuperation des vehicule et entregistrer en cache
    this.getAllVoitureWith2Img();

  }

  getAllVoitureWith2Img(){
    this.loading = true;
this.authService.getAllVoitureWith2images()
  .subscribe(data => {
    //console.log(data);
    this.listVoitures =data;
    //console.log('----------->>>>>>>>'); 
    //console.log(this.listVoitures);
    sessionStorage.removeItem('voiture');
    sessionStorage.setItem('voiture', JSON.stringify(this.listVoitures));
    /*  
    for (let p of this.listVoitures) { 
    if(p.imageBase1){
       this.retrieveResonse = p.imageBase1;
      this.base64Data = this.retrieveResonse.picByte;
      this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
      p.imageBase1=this.retrievedImage;
      }
      if(p.imageBase2){
        this.retrieveResonse2 = p.imageBase2;
       this.base64Data2 = this.retrieveResonse2.picByte;
       this.retrievedImage2 = 'data:image/jpeg;base64,' + this.base64Data2;
       p.imageBase2=this.retrievedImage2;
       }
    }
    */
    this.loading = false;
},
error => {
  this.loading = false;
  console.log(error);
} 
);
}

}
