import { Component, OnInit } from '@angular/core';
import { motdepasse, login } from 'app/Utils/const';
import { Router } from '@angular/router';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    test : Date = new Date();
    focus;
    focus1;

    motdepasse;
    login;

    constructor(private router: Router) { }

    ngOnInit() {}

signIn(){
    //console.log(this.motdepasse);
    //console.log(this.login);
    if(this.login===login && this.motdepasse === motdepasse){
        localStorage.setItem('jeton', login); 
        this.router.navigate(['/admin']);
    }
}



}
