import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-factures',
  templateUrl: './factures.component.html',
  styleUrls: ['./factures.component.css']
})
export class FacturesComponent implements OnInit {

  constructor(private  authService: AuthService, private router: Router) { }
  listFactures;
  public loading = false;

  ngOnInit(): void {
    this.authService.espaceAdmin();
    this.getAllFacture();
  } 

  getAllFacture(){
    this.authService.getAllFactures()
    .subscribe(data=>{
      this.listFactures=data;
      //console.log(this.listFactures)
    },
    error => {
      console.log(error);
    }
  );
  }

  detailFacture(facture){
    this.router.navigate(['/detailFacture', facture.idFacture]);
  }


  imprimer(facture): void {
    this.loading = true;
    this.authService.dowloadFacture(facture.idFacture)
        .subscribe(x => {
            var newBlob = new Blob([x], { type: "application/pdf" });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = "Facture_N"+facture.idFacture+".pdf";
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
                link.remove();
                
            }, 100);
            this.loading = false;
           
        },error=>{
          this.loading = false;
          alert(error);
        });
  }

  motCle;
  rechercheFactu(){
    this.authService.getFactureByRecherche(this.motCle)
    .subscribe(data=>{
      this.listFactures=data;
    },
    error => {
      console.log(error);
    }
  );
  }


}
