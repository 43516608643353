import { Component, OnInit } from '@angular/core';
import { Facture } from 'app/models/Facture';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { Produit } from 'app/models/Produit';

@Component({
  selector: 'app-detail-facture',
  templateUrl: './detail-facture.component.html',
  styleUrls: ['./detail-facture.component.css']
})
export class DetailFactureComponent implements OnInit {
  facture;
  idFacture;
  produit;
  listProduitQuantite;
  public loading = false;

  constructor(private route : ActivatedRoute, private  authService: AuthService, public router: Router) { }

  ngOnInit(): void {
    this.authService.espaceAdmin();
    this.facture = new Facture;
    this.produit = new Produit;
    this.route.paramMap.subscribe(params => {
      this.idFacture = params.get("idFacture");
    //  console.log(params.get("idFacture"));
      this.getFactureById(this.idFacture);
      this.getProduitFacture(this.idFacture);
    })
  }


  getFactureById(idFacture){  
    this.authService.getFactureById(idFacture)
    .subscribe(data=>{
      this.facture=data;
   //   console.log(this.facture); 
    },
    error => {
      console.log(error);
    }
  );
  }

  getProduitFacture(idFacture){
    this.authService.getProduitsByIdFacture(idFacture)
    .subscribe(data=>{
      this.listProduitQuantite=data;
    //  console.log(this.listProduitQuantite); 
    },
    error => {
      console.log(error);
    }
  );
  }


/*
  calculTotaux(): void {
    this.facture.total=0;
    this.facture.totalsansTVA=0;
    this.facture.factureTVA=0;
		for (var i = 0; i < this.listProduitQuantite.length; i++) {
      let produitQuantite = this.listProduitQuantite[i];
      this.facture.total += produitQuantite.prixQuant;
      this.facture.totalsansTVA += produitQuantite.prixUnite * produitQuantite.quantite;
      this.facture.factureTVA += produitQuantite.tva / this.listProduitQuantite.length; 
    }  
  
}*/

  imprimer(): void {
    this.loading = true;
    this.authService.dowloadFacture(this.idFacture)
        .subscribe(x => {
            var newBlob = new Blob([x], { type: "application/pdf" });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = "Facture_N"+this.idFacture+".pdf";
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.loading = false;
        },error=>{
          this.loading = false;
          alert(error);
        });
  }

  deleteFacture(){
    this.deleteFactureById(this.idFacture)
  }

  deleteFactureById(idFacture){
    this.loading = true;
    this.authService.deleteFactureById(idFacture)
    .subscribe(data=>{
     // console.log(data);
      this.loading = false;
      this.router.navigate(['/factures']); 
    },
    error => {
      console.log(error);
      this.loading = false;
    }
  );
  }

  upDateFacture(){ 
    this.loading = true;
    this.authService.upDateFacture(this.facture) 
    .subscribe(data=>{
      this.facture = data;
      this.loading = false;
      alert('facture mise a jour'); 
    },
    error => {
      console.log(error);
      this.loading = false;
      alert('facture n est enregistrée ERREUR coté serveur !!!');
    }
  );
  }

  ajouterProduit(){
   // console.log(this.produit); 
   // console.log(this.idFacture);
    this.ajouterUnProduitFacture(this.produit, this.idFacture);
  //  this.listProduitQuantite.push(this.produit);
  }

 
  removeProduit(idProduit): void {
    let confirm = window.confirm('êtes-vous sûr de vouloir supprimer le produit?');
    if(confirm==true){ 
      //console.log('--->>>');
      this.supprimerUnProduitFacture(idProduit, this.idFacture)
   }
  }

  prixQuant=0;
  //tva = 20/100; 
  ajouterUnProduitFacture(produit, idFacture){
    this.prixQuant = this.produit.quantite * (this.produit.prixUnite + (this.produit.prixUnite * (this.produit.tva/100)));
    this.prixQuant = Math.round(this.prixQuant * 100) / 100;
    this.produit.prixQuant = this.prixQuant;
    this.loading = true;
    this.authService.ajouterUnProduitFacture(produit, idFacture) 
    .subscribe(data=>{
      this.getProduitFacture(this.idFacture);
      this.getFactureById(this.idFacture);
      this.loading = false;
      alert('produit Ajouté'); 
      this.produit = new Produit;
    },
    error => {
      console.log(error);
      this.loading = false;
      alert('ERREUR Produit non ajouter');
    }
  );
  }

  supprimerUnProduitFacture(idProduit, idFacture){
    this.loading = true;
    this.authService.supprimerUnProduitFacture(idProduit, idFacture) 
    .subscribe(data=>{
      this.getProduitFacture(this.idFacture);
      this.getFactureById(this.idFacture);
      this.loading = false; 
      alert('Produit Supprimer');
    },
    error => {
      console.log(error);
      this.loading = false;
      alert('ERREUR Produit non supprimer');
    }
  );
  }
  

}
