import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtResponse } from './jwt-response';
import { AuthLoginInfo } from './login-info';
import { SignUpInfo } from './signup-info';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';
import { SERVER_URL, login } from 'app/Utils/const';
import { HashLocationStrategy } from '@angular/common';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private resourceUri = SERVER_URL + 'api/auth/'
  private urlAdmin = SERVER_URL+'admin/'; 
  constructor(private http: HttpClient,
    private token: TokenStorageService,
    public router: Router) {
  }

  attemptAuth(credentials: AuthLoginInfo): Observable<JwtResponse> {
    return this.http.post<JwtResponse>(this.resourceUri+'signin', credentials, httpOptions);
  }

signUp(info: SignUpInfo){
  //console.log('signup service')
  return this.http.post(this.resourceUri+'signup',  info, {responseType: 'text'});
}
  getAdminBoard(): Observable<string> { 
    return this.http.get(SERVER_URL+'api/test/admin', { responseType: 'text' });
  } 


  saveVoiture(voiture){
    return this.http.post(this.urlAdmin+"saveVoiture", voiture);
  }

  getAllVoiture(){
        return this.http.get(this.urlAdmin+"getAllVoiture");
  }

  getVoitureById(idVoiture){
    return this.http.get(this.urlAdmin+"getVoitureById/"+idVoiture);
  }

  modifVoiture(voiture){
    return this.http.put(this.urlAdmin+"modifVoiture/", voiture);
  }

  onUploadImageWithIdVoiture(uploadImageData, idVoiture){
    return this.http.post(SERVER_URL+'image/uploadWithIdVoiture/'+idVoiture, uploadImageData, { observe: 'response' })
  }


  getImagesWithIdVoiture(idVoiture){
    return this.http.get(SERVER_URL+'image/getImageByIdVoiture/' + idVoiture)
  }

  deleteImageVoiture(idImage){
    return this.http.get(SERVER_URL+'image/deleteImageById/' + idImage)
  }

  deleteVoitureWithImage(idVoiture) {
    return this.http.get(this.urlAdmin+"deleteVoitureById/"+idVoiture);
  }

  getAllVoitureWith2images(){
    return this.http.get(this.urlAdmin+"getAllVoitureWith2images");
  }

  saveFacture(facture){
    return this.http.post(this.urlAdmin+"saveFacture", facture);
  }

  saveProduitFacture(listProduitFacture){
    return this.http.post(this.urlAdmin+"saveListProduitFacture", listProduitFacture);
  }

  getAllFactures(){
    return this.http.get(this.urlAdmin+"getAllFacture");
  }

  getFactureById(idFacture){
    return this.http.get(this.urlAdmin+"getFactureById/"+idFacture);
  }

  getProduitsByIdFacture(idFacture){
    return this.http.get(this.urlAdmin+"getProduitsByIdFacture/"+idFacture);
  }


  dowloadFacture(idFacture){
      let uri = this.urlAdmin+"dowloadFacture/"+idFacture;
      return this.http.get(uri, { responseType: 'blob' });
  }

  espaceAdmin(){
    let jeton = localStorage.getItem('jeton'); 
     if(jeton != login){
       alert('Vous n\'avez pas accés a cette page')
       this.router.navigate(['/home']); 
     }
   }

   deleteFactureById(idFacture){
    return this.http.delete(this.urlAdmin+"deleteFactureById/"+idFacture);
  }

  getFactureByRecherche(motCle){
    return this.http.post(this.urlAdmin+"getFactureByRecherche", motCle);
  }

  upDateFacture(facture) {
    return this.http.put(this.urlAdmin+"modifFacture/", facture);
  }

  upDateProduitFacture(listProduitFacture){
    return this.http.post(this.urlAdmin+"updateListProduitFacture", listProduitFacture);
  }

  ajouterUnProduitFacture(produit, idFacture){
    return this.http.post(this.urlAdmin+"ajouterUnProduitFacture/"+idFacture, produit);
  }

  supprimerUnProduitFacture(idProduit, idFacture){
    return this.http.post(this.urlAdmin+"supprimerUnProduitFacture/"+idFacture, idProduit);
  }

  saveRendezVous(fromRendezVous){
    return this.http.post(this.urlAdmin+"saveRendezVous", fromRendezVous);
  }
  
  getRendezVousParDate(dateRVChercher){
    return this.http.get(this.urlAdmin+"getRendezVousParDate/"+dateRVChercher);
  }

  saveOperation(operation){
    return this.http.post(this.urlAdmin+"saveOperation", operation);
  }

  getOperationByDate(dateOper){
    return this.http.get(this.urlAdmin+"getOperationByDate/"+dateOper);
  }

  deleteOperationById(idOperation){
    return this.http.delete(this.urlAdmin+"deleteOperationById/"+idOperation);
  }

  getTotalCaisseByDate(date){
    return this.http.get(this.urlAdmin+"getTotalCaisseByDate/"+date);
  }

}
