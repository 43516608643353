import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { horraire } from 'app/Utils/const';

@Component({
  selector: 'app-saisir-rendez-vous',
  templateUrl: './saisir-rendez-vous.component.html',
  styleUrls: ['./saisir-rendez-vous.component.css']
})
export class SaisirRendezVousComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal,
              private  authService: AuthService) { }
  rendezVous: any;
  horraire;
  matricule;
  loading: boolean = false;

  ngOnInit(): void {
    this.horraire = horraire
  }

  ajouterRendezVous(form){
    console.log('--->>>>>>>>');
    console.log(form);
    this.loading = true;
    //console.log(voitureForm);
    this.authService.saveRendezVous(form)
    .subscribe(data=>{
      this.rendezVous=data;
      this.loading = false;
      alert('rendez vous enregistrée');
      this.exitModal();
    },
    error => {
      console.log(error);
      this.loading = false;
      this.exitModal();
    }
  );
  }

  exitModal(){
    this.activeModal.dismiss();
  }

}
