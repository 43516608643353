import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SERVER_URL } from 'app/Utils/const';
import { HttpClient } from "@angular/common/http";
import { Mail } from 'app/models/MailModel';




@Injectable()
export class mailService {
    constructor(private http:HttpClient) { }

    private resourceUri = SERVER_URL + 'mail/';

sendMail (mail: Mail){
        return this.http.post(this.resourceUri+"envoi", mail);
     }
}