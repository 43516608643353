import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { BasicelementsComponent } from './basicelements/basicelements.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { NucleoiconsComponent } from './nucleoicons/nucleoicons.component';
import { ComponentsComponent } from './components.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbdModalComponent } from './modal/modal.component';
import { NgbdModalContent } from './modal/modal.component';
import { ContactComponent } from './contact/contact.component';
import { AccueilComponent } from './accueil/accueil.component';
import { VehiculesComponent } from './vehicules/vehicules.component';
import { RechercheComponent } from './recherche/recherche.component';
import { AdminComponent } from './Admin/admin/admin.component';
import { AjoutVoitureComponent } from './Admin/admin/ajout-voiture/ajout-voiture.component';
import { DetailModifVoitureComponent } from './Admin/admin/detail-modif-voiture/detail-modif-voiture.component';
import { DetailVehiculeComponent } from './vehicules/detail-vehicule/detail-vehicule.component';
import { CreateFactureComponent } from './Admin/facture/create-facture/create-facture.component';
import { FacturesComponent } from './Admin/facture/factures/factures.component';
import { DetailFactureComponent } from './Admin/facture/detail-facture/detail-facture.component';
import { PriseRendezVousComponent } from './Admin/prise-rendez-vous/prise-rendez-vous.component';
import { SaisirRendezVousComponent } from './Admin/prise-rendez-vous/saisir-rendez-vous/saisir-rendez-vous.component';
import { EtatCaisseComponent } from './Admin/etat-caisse/etat-caisse.component';
import { FinancementComponent } from './financement/financement.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module
    ],
    declarations: [
        ComponentsComponent,
        BasicelementsComponent,
        NavigationComponent,
        TypographyComponent,
        NucleoiconsComponent,
        NotificationComponent,
        NgbdModalComponent,
        NgbdModalContent,
        ContactComponent,
        AccueilComponent,
        VehiculesComponent,
        RechercheComponent,
        AdminComponent,
        AjoutVoitureComponent,
        DetailModifVoitureComponent,
        DetailVehiculeComponent,
        CreateFactureComponent,
        FacturesComponent,
        DetailFactureComponent,
        PriseRendezVousComponent,
        SaisirRendezVousComponent,
        EtatCaisseComponent,
        FinancementComponent
    ],
    entryComponents: [NgbdModalContent],
    exports:[ ComponentsComponent ]
})
export class ComponentsModule { }
