import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { horraire } from 'app/Utils/const';
import { SaisirRendezVousComponent } from './saisir-rendez-vous/saisir-rendez-vous.component';

@Component({
  selector: 'app-prise-rendez-vous',
  templateUrl: './prise-rendez-vous.component.html',
  styleUrls: ['./prise-rendez-vous.component.css']
})
export class PriseRendezVousComponent implements OnInit {
  listRendezVous: any;
  horraire;
  matricule;
  loading;
  dateRVChercher=null;


  constructor(private modalService: NgbModal,
              private authService: AuthService) { 
    }


  ngOnInit(): void {
    this.horraire = horraire;

    this.dateRVChercher="2021-03-23";
    this.getRendezVousParDate()
  }

  
  prendreRendezVous(){
    this.openPriseRendezVous()  
  }

  openPriseRendezVous() {
    this.modalService.open(SaisirRendezVousComponent, { size: 'lg', backdrop: 'static' });
   }

   getRendezVousParDate(){
     if(this.dateRVChercher===null){
       alert('veuillez choisir une date');
     }
    console.log(this.dateRVChercher);
    console.log('--->>>>>>>>');
    this.loading = true;
    this.authService.getRendezVousParDate(this.dateRVChercher)
    .subscribe(data=>{
      this.listRendezVous=data;
      this.loading = false;
    },
    error => {
      console.log(error);
      this.loading = false;
    }
  );
}


}
