import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicules',
  templateUrl: './vehicules.component.html',
  styleUrls: ['./vehicules.component.css']
})
export class VehiculesComponent implements OnInit {


  constructor(private  authService: AuthService, private router: Router) { }
  listVoitures ;
  selectedFile: File = null;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;

  retrievedImage2: any;
  base64Data2: any;
  retrieveResonse2: any;
  public loading = false;

  ngOnInit(): void { 
    //this.getAllVoitureWith2Img();
    console.log(sessionStorage.getItem('voiture'));

    if(sessionStorage.getItem('voiture')){
          this.listVoitures = JSON.parse(sessionStorage.getItem('voiture'));
    for (let p of this.listVoitures) {
      if(p.imageBase1){
         this.retrieveResonse = p.imageBase1;
        this.base64Data = this.retrieveResonse.picByte;
        this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
        p.imageBase1=this.retrievedImage;
        }
        if(p.imageBase2){
          this.retrieveResonse2 = p.imageBase2;
         this.base64Data2 = this.retrieveResonse2.picByte;
         this.retrievedImage2 = 'data:image/jpeg;base64,' + this.base64Data2;
         p.imageBase2=this.retrievedImage2;
         }
      }
    }else{
      this.getAllVoitureWith2Img();
    }


  } 
 
  getAllVoitureWith2Img(){ 
        this.loading = true;
    this.authService.getAllVoitureWith2images()
      .subscribe(data => {
        //console.log(data);
        this.listVoitures =data;
        //console.log('----------->>>>>>>>'); 
        //console.log(this.listVoitures);
        sessionStorage.removeItem('voiture');
        sessionStorage.setItem('voiture', this.listVoitures);
        for (let p of this.listVoitures) {
        if(p.imageBase1){
           this.retrieveResonse = p.imageBase1;
          this.base64Data = this.retrieveResonse.picByte;
          this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
          p.imageBase1=this.retrievedImage;
          }
          if(p.imageBase2){
            this.retrieveResonse2 = p.imageBase2;
           this.base64Data2 = this.retrieveResonse2.picByte;
           this.retrievedImage2 = 'data:image/jpeg;base64,' + this.base64Data2;
           p.imageBase2=this.retrievedImage2;
           }
        }
        this.loading = false;
    },
    error => {
      this.loading = false;
      console.log(error);
    } 
  );
  }

  detailVoiture(idVoiture){
    //console.log('detailVoiture');
    //console.log(idVoiture);
    //alert(idVoiture); 
    this.router.navigate(['/detailVehicule', idVoiture]);  
  }

 
/*
selectedFile: File = null;
retrievedImage: any;
base64Data: any;
retrieveResonse: any;
listRetrievedImage = [];
getImagesWithIdVoiture(idVoiture) {
  this.listRetrievedImage = [];
  this.authService.getImagesWithIdVoiture(idVoiture)
  .subscribe((res: any[]) => {
    for (var i = 0; i < res.length; i++) {
      let image = res[i];
       this.retrieveResonse = image;
       this.base64Data = this.retrieveResonse.picByte;
       this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
       this.listRetrievedImage.push({
         idImage: res[i].id,
        imag : this.retrievedImage
     });
    }
},
error => { 
  console.log(error);
});
}
*/


}
