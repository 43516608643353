import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { AuthService } from './services/confSecurity/auth.service';
import { Voiture } from './models/Voiture';
import { VoitureService } from './services/voitureService';
import { HttpClientModule } from '@angular/common/http';
import { mailService } from './services/mailService';
//import { AgmCoreModule } from '@agm/core';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SaisirRendezVousComponent } from './components/Admin/prise-rendez-vous/saisir-rendez-vous/saisir-rendez-vous.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    ExamplesModule,
    AppRoutingModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)', 
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff', 
      secondaryColour: '#ffffff', 
      tertiaryColour: '#ffffff'
  })
      /*AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAF89AqBeGG2RA9nO-CmzIg8pGZ6W7unwA' 
    }),*/
  ],
  providers: [
    AuthService,
    VoitureService,
    mailService
  ],
  entryComponents: [
    SaisirRendezVousComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
