import { Produit } from "./Produit";

export class Facture{
    idFacture;
    nomClient;
    entreprise;
    vehicule;
    matricule;
    tel; 
    adresse;
    email;
    adressFact='Bennecourt';
    leDate;
    total;
    totalsansTVA;
    factureTVA;
    paiement;
    }