import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { VehiculesComponent } from './components/vehicules/vehicules.component';
import { RechercheComponent } from './components/recherche/recherche.component';
import { AdminComponent } from './components/Admin/admin/admin.component';
import { AjoutVoitureComponent } from './components/Admin/admin/ajout-voiture/ajout-voiture.component';
import { DetailModifVoitureComponent } from './components/Admin/admin/detail-modif-voiture/detail-modif-voiture.component';
import { DetailVehiculeComponent } from './components/vehicules/detail-vehicule/detail-vehicule.component';
import { ContactComponent } from './components/contact/contact.component';
import { CreateFactureComponent } from './components/Admin/facture/create-facture/create-facture.component';
import { FacturesComponent } from './components/Admin/facture/factures/factures.component';
import { DetailFactureComponent } from './components/Admin/facture/detail-facture/detail-facture.component';
import { PriseRendezVousComponent } from './components/Admin/prise-rendez-vous/prise-rendez-vous.component';
import { EtatCaisseComponent } from './components/Admin/etat-caisse/etat-caisse.component';
import { FinancementComponent } from './components/financement/financement.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'signup',           component: SignupComponent },
    { path: 'landing',          component: LandingComponent },
    { path: 'nucleoicons',      component: NucleoiconsComponent },

    { path: 'vehicules',      component: VehiculesComponent },
    { path: 'recherche',      component: RechercheComponent },
    { path: 'admin',          component: AdminComponent },
    { path: 'ajoutVoiture',   component: AjoutVoitureComponent },
    { path: 'detailVoitAdmin/:idVoiture',   component: DetailModifVoitureComponent },
    { path: 'detailVehicule/:idVoiture',   component: DetailVehiculeComponent },
    { path: 'contact', component: ContactComponent},
    { path: 'factures', component: FacturesComponent} ,
    { path: 'createfactures', component: CreateFactureComponent} ,
    { path: 'detailFacture/:idFacture', component: DetailFactureComponent},
    { path: 'rendezvous', component: PriseRendezVousComponent} ,
    { path: 'etatCaisse', component: EtatCaisseComponent} ,
    { path: 'financement', component: FinancementComponent} 
  ];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
