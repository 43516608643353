import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { Voiture } from 'app/models/Voiture';
import { login } from 'app/Utils/const';

@Component({
  selector: 'app-detail-modif-voiture',
  templateUrl: './detail-modif-voiture.component.html',
  styleUrls: ['./detail-modif-voiture.component.css']
})
export class DetailModifVoitureComponent implements OnInit {

  constructor(private route : ActivatedRoute, private  authService: AuthService, private router: Router) { }
  idVoiture
  voiture;
  public loading = false;

  ngOnInit(): void {
    this.authService.espaceAdmin();
    this.voiture = new Voiture;
    this.route.paramMap.subscribe(params => {
      this.idVoiture = params.get("idVoiture");
      //console.log(params.get("idVoiture"));
      this.getVoitureById(this.idVoiture);
      this.getImagesWithIdVoiture(this.idVoiture);
    })
  }


 

  getVoitureById(idVoiture){
    this.authService.getVoitureById(idVoiture)
    .subscribe(data=>{
      this.voiture=data;
      //console.log(this.voiture);
    },
    error => {
      console.log(error);
    }
  );
  }

  modifierVoiture(){
    this.loading = true;
    this.authService.modifVoiture(this.voiture)
    .subscribe(data=>{
      this.voiture=data;
      //console.log(this.voiture);
      this.loading = false;
      alert('Voiture mis a jour');
      this.router.navigate(['/admin']);
    },
    error => {
      console.log(error);
      this.loading = false;
    }
  );
  }


  
deleteUpLoad(element){
  element.value = "";
}

selectedFile: File = null;
retrievedImage: any;
base64Data: any;
retrieveResonse: any;
message: string;
imageName: any;
public onFileChanged(event) {
  //Select File
  this.selectedFile = event.target.files[0];
  /*if(this.selectedFile.size>50000){
    alert('L\'image ne sera pas enregistrer. Image trés volumineuse merci d inserer une image < 50 Ko!');
  }*/
}


onUpload(idVoiture) {
//  console.log('--->>'+this.selectedFile);
//console.log('--->>'+idVoiture);
this.loading = true;
const uploadImageData = new FormData();
  uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
 this.authService.onUploadImageWithIdVoiture(uploadImageData, idVoiture)
    .subscribe((response) => {
      //console.log('response: '+response);
      if (response.status === 200) {
        this.message = 'Image uploaded successfully';
        alert('image enregistrée avec succés');
      } else {
        this.message = 'Image not uploaded successfully';
        alert('ERREUR image non enregistrée');
      }
      this.loading = false;
    }
      ,error => {
      console.log('error =' +error);
      if (error.status === 200) {
        this.message = 'Image uploaded successfully';
        alert('image enregistrée avec succés');
        this.getImagesWithIdVoiture(this.idVoiture) ; 
      } else {
        this.message = 'Image not uploaded successfully';
        alert('ERREUR image non enregistrée');
      }
      this.loading = false;
});
}
/*
voitureImageList;
getImagesWithIdVoiture(idVoiture){
  console.log('getImagesWithIdVoiture')
this.authService.getImagesWithIdVoiture(idVoiture)
.subscribe(data=>{ 
  this.voitureImageList=data;
  console.log(this.voitureImageList);
},
error => {
  console.log(error);
});
}*/
listRetrievedImage = [];
getImagesWithIdVoiture(idVoiture) {
  this.listRetrievedImage = [];
  this.authService.getImagesWithIdVoiture(idVoiture)
  .subscribe((res: any[]) => {
    for (var i = 0; i < res.length; i++) {
      let image = res[i];
       this.retrieveResonse = image;
       this.base64Data = this.retrieveResonse.picByte;
       this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
       this.listRetrievedImage.push({
         idImage: res[i].id,
        imag : this.retrievedImage
     });
    }
},
error => { 
  console.log(error);
});
}

supprimerImage(idImage){
let confirm = window.confirm('etes vous sur de vouloir supprimer la photo de la voiture');
if(confirm==true){ 
   this.authService.deleteImageVoiture(idImage) 
   .subscribe(data=>{
     this.getImagesWithIdVoiture(this.idVoiture) ;
 },error => {
  this.getImagesWithIdVoiture(this.idVoiture) ;
   console.log(error);  
 })
}
}

}