export class Mail{
    email: string = "";
    nomPrenom: string = "";
    entreprise: string = "";
    tel: string = "";
    objetMail: string = "";
    msg: string = "";

}

	