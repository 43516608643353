import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { Voiture } from 'app/models/Voiture';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detail-vehicule',
  templateUrl: './detail-vehicule.component.html',
  styleUrls: ['./detail-vehicule.component.css'],

  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }
  `]
  
})
export class DetailVehiculeComponent implements OnInit {

  constructor(private route : ActivatedRoute, private  authService: AuthService,
    private modalService: NgbModal ) { }

  idVoiture;
  voiture;

   
  ngOnInit(): void {
    this.voiture = new Voiture;
    this.route.paramMap.subscribe(params => {
      this.idVoiture = params.get("idVoiture");
      //console.log(params.get("idVoiture"));
      this.getVoitureById(this.idVoiture);
      this.getImagesWithIdVoiture(this.idVoiture);
    })
  }


  getVoitureById(idVoiture){
    this.authService.getVoitureById(idVoiture)
    .subscribe(data=>{
      this.voiture=data;
      //console.log(this.voiture);
    },
    error => {
      console.log(error);
    }
  );
  }

  listRetrievedImage = [];
  selectedFile: File = null;
retrievedImage: any;
base64Data: any;
retrieveResonse: any;
message: string;
imageName: any;

  getImagesWithIdVoiture(idVoiture) {
    this.listRetrievedImage = [];
    this.authService.getImagesWithIdVoiture(idVoiture)
    .subscribe((res: any[]) => {
      for (var i = 0; i < res.length; i++) {
        let image = res[i];
         this.retrieveResonse = image;
         this.base64Data = this.retrieveResonse.picByte;
         this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
         this.listRetrievedImage.push({
           idImage: res[i].id,
          imag : this.retrievedImage
       });
      }
  },
  error => { 
    console.log(error);
  });
  }

  imgShow = "/assets/img/loading3.gif"
  show = false;
  showImage(img){
   // document.getElementById('modal01').style.display='block'
    this.show = true;
    //console.log("test");
    //console.log(img);
    this.imgShow=img;
  }

}
