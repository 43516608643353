import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { mailService } from 'app/services/mailService';
import { Mail } from 'app/models/MailModel';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  mail : Mail = new Mail();

  constructor(
    public router: Router,
    public mailService: mailService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  test(){
    alert('Impossible d\'envoyer le message, erreur au niveau du serveur ! \n Merci de nous contacter par téléphone');
  }

  sendMsg (dataForm){
    this.mailService.sendMail(dataForm)
    .subscribe((response) => {
        alert('Votre message a été envoyer avec succés');
        this.mail = new Mail();
      }
      ,error => {
      console.log('error =' +error);
      if (error.status === 200) {
        alert('Votre message a été envoyer avec succés');
        this.mail = new Mail();
      } else {
        alert('Impossible d\'envoyer le message, erreur au niveau du serveur ! \n Merci de nous contacter sur "contact@chbauto.com"');
      }
});
   }

}
