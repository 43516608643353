import { HttpClient } from "@angular/common/http";
import { SERVER_URL } from "app/Utils/const";
import { Injectable } from "@angular/core";


@Injectable()
export class VoitureService {
   constructor(private https: HttpClient) { }
   
   private resourceUri = SERVER_URL + 'admin/';

}