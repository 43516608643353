import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { Router } from '@angular/router';
import { login } from 'app/Utils/const';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  listVoitures;
  constructor(private  authService: AuthService, private router: Router) { }

  ngOnInit(): void { 
    //console.log(localStorage.getItem('jeton'))
    this.authService.espaceAdmin();
    this.getAllVoiture();
  } 


  deconnexion(){
    localStorage.removeItem('jeton');
    this.router.navigate(['/home']); 
  }

  getAllVoiture(){
    this.authService.getAllVoiture()
    .subscribe(data=>{
      this.listVoitures=data;
      //console.log(this.listVoitures)
    },
    error => {
      console.log(error);
    }
  );
  }

  onDeleteVoiture(v){
    //console.log(v.idVoiture);
    let confirm = window.confirm('etes vous sur de vouloir supprimer  la voiture?');
if(confirm==true){ 
   this.authService.deleteVoitureWithImage(v.idVoiture) 
   .subscribe(data=>{
     this.getAllVoiture() ;
 },error => {
  this.getAllVoiture() ;
   console.log(error);  
 })
}
  }

  ModifVoiture(v){
    //console.log(v.idVoiture)
    this.router.navigate(['/detailVoitAdmin', v.idVoiture]);
  }

}
