import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/confSecurity/auth.service';

@Component({
  selector: 'app-etat-caisse',
  templateUrl: './etat-caisse.component.html',
  styleUrls: ['./etat-caisse.component.css']
})
export class EtatCaisseComponent implements OnInit {

  typeOper;
  public loading = false;
  operation;
  listOperationByDate;
  totalCaisse;
  dateOperation;

  constructor( private  authService: AuthService,
               private router: Router) { }

  ngOnInit(): void {
    this.typeOper=[
      {catego:"Entree"},
      {catego:"Sortie"}
    ];
   
    this.dateOperation="26/07/2021";
    this.getOperationByDateBt()

  }


  ajouterOperation(operationForm){
    //console.log(operationForm);
    this.loading = true;
    //console.log(voitureForm);
    this.authService.saveOperation(operationForm)
    .subscribe(data=>{
      this.operation=data;
      this.loading = false;
      alert('Operation enregistrée');
      this.router.navigate(['/etatCaisse']);
    },
    error => {
      console.log(error);
      this.loading = false;
    }
  );
  }


  getOperationByDateBt(){
    //console.log ('------------->>>>>>>>>>>>>>>');
    //console.log (this.dateOperation);
    this.getOperationByDate(this.dateOperation);
    this.getTotalCaisseByDate(this.dateOperation);
    console.log(this.dateOperation)
  }

  getOperationByDate(date){
    //console.log(date);
    this.loading = true;
    //console.log(voitureForm);
    this.authService.getOperationByDate(date)
    .subscribe(data=>{
      this.listOperationByDate=data;
      this.loading = false;
      console.log (this.listOperationByDate);
     // alert('Operation enregistrée');
     // this.router.navigate(['/etatCaisse']);
    },
    error => {
      console.log(error);
      this.loading = false;
    }
  );
  }

  deleteOperationById(idOperation){
    this.loading = true;
    //console.log(voitureForm);
    this.authService.deleteOperationById(idOperation)
    .subscribe(data=>{
      this.loading = false;
      //console.log (data);
     // alert('Operation enregistrée');
     // this.router.navigate(['/etatCaisse']);
    },
    error => {
      console.log(error);
      this.loading = false;
    }
  ); 
  }


  getTotalCaisseByDate(date){
    this.loading = true;
    //console.log(voitureForm);
    this.authService.getTotalCaisseByDate(date)
    .subscribe(data=>{
      this.loading = false;
      this.totalCaisse=data;
     // console.log ('--------->>>>>>>>');
     // console.log (this.totalCaisse);
     // alert('Operation enregistrée');
     // this.router.navigate(['/etatCaisse']);
    },
    error => {
      console.log(error);
      this.loading = false;
    }
  ); 
  }
  

}
