import { Component, OnInit } from '@angular/core';
import { Voiture } from 'app/models/Voiture';
import { AuthService } from 'app/services/confSecurity/auth.service';
import { Router } from '@angular/router';
import { login } from 'app/Utils/const';

@Component({
  selector: 'app-ajout-voiture',
  templateUrl: './ajout-voiture.component.html',
  styleUrls: ['./ajout-voiture.component.css']
})
export class AjoutVoitureComponent implements OnInit {

  voiture: Voiture;
  voitureSaved;
  public loading = false;

  constructor( private  authService: AuthService,
               private router: Router) { }

  ngOnInit(): void {
    this.authService.espaceAdmin();
    this.voiture = new Voiture;
  }

 
 

  ajouterVoiture(voitureForm){
    this.loading = true;
    //console.log(voitureForm);
    this.authService.saveVoiture(voitureForm)
    .subscribe(data=>{
      this.voitureSaved=data;
      this.loading = false;
      alert('Voiture enregistrée');
      this.router.navigate(['/admin']);
    },
    error => {
      console.log(error);
      this.loading = false;
    }
  );
  }

}
